<script>
import { Card, Modal, List, Message } from 'ant-design-vue'
import { getAction } from '../../../command/netTool'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    titleCopy: {
      type: String,
      default: ''
    },
    dataSource: {
      type: Array,
      default: () => {
        return []
      }
    },
    typeObjData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    searchIndex: {
      // 搜索状态
      type: String,
      default: '0'
    },
    numberBusinesses: {
      // 商家数量
      type: Number,
      default: 0
    },
    delShopIds: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      inputlength: 0,
      visible: false,
      visibleClear: false,
      name: null,
      //   searchIndex: 0,
      //   numberBusinesses: 0,
      listData: [], // 更换商家列表弹出框数据
      listDataCopy: [], // 搜索更换商家列表弹出框数据
      searchData: {}, // 选择更换商家数据
      chooseData: {}, // 选择列表更换商家
      selectedRowKeys: [],
      delShopIdsCopy: [],
      delShopIds_copy: []
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
          this.delShopIds_copy = selectedRows
        }
      }
    }
  },
  watch: {
    name() {
      this.handleSrarchStore()
    },
    searchIndex() {
      this.searchMerchants()
    },
    'typeObjData.scenicLabel'(a, b) {
      if (b) {
        this.searchMerchants()
      }
    },
    'typeObjData.experienceFish'(a, b) {
      if (b) {
        this.searchMerchants()
      }
    },
    'typeObjData.farmExperiencePick'(a, b) {
      if (b) {
        this.searchMerchants()
      }
    },
    delShopIds(a, b) {
      if (a) {
        this.delShopIdsCopy = this.delShopIds.split(',')
        console.log(this.delShopIdsCopy, '==this.delShopIdsCopy==')
      }
    }
  },
  mounted() {},
  methods: {
    getColumns() {
      let _this = this
      return [
        {
          title: '排名',
          dataIndex: 'sort',
          width: '20%'
        },
        {
          title: '商家名称',
          dataIndex: 'storeName',
          width: '20%'
        },
        {
          title: '自定义副标题（必填）',
          dataIndex: 'subTile',
          width: '50%',
          customRender: function customRender(text, record, index) {
            record.inputlength = text.length
            let onInput = e => {
              record.subTile = e.target.value
            }

            return <a-input value={text} maxLength={8} onChange={onInput} suffix={`${record.inputlength}/8`} />
          }
        },
        {
          title: '操作',
          width: 100,
          fixed: 'right',
          customRender: function customRender(text, record, index) {
            let onInput = e => {
              _this.visible = true

              _this.chooseData = record
              let arrId = _this.dataSource.map(e => e.storeId).toString()

              getAction(
                `/api/commodity/farmMainBody/getMainBodyByLabelList?delShopIds=${_this.delShopIdsCopy.toString()}&allLabel=${
                  _this.searchIndex
                }&scenicLabel=${_this.typeObjData.scenicLabel}&pickLabel=${
                  _this.typeObjData.farmExperiencePick
                }&anglingLabel=${_this.typeObjData.experienceFish}&shopIds=${arrId}`
              ).then(result => {
                if (result.code == 200) {
                  _this.listData = result.data
                } else {
                  Message.error(result.msg)
                }
              })
            }

            return <a onClick={onInput}>更换商家</a>
          }
        }
      ]
    },
    getColumnsStop() {
      return [
        {
          title: '店铺名称',
          dataIndex: 'mainBodyName',
          align: 'left'
        }
      ]
    },
    searchMerchants() {
      this.delShopIdsCopy = []
      getAction(
        `/api/commodity/farmMainBody/getMainBodyByLabelListTop3?allLabel=${this.searchIndex}&scenicLabel=${this.typeObjData.scenicLabel}&pickLabel=${this.typeObjData.farmExperiencePick}&anglingLabel=${this.typeObjData.experienceFish}`
      ).then(result => {
        if (result.code == 200) {
          if (this.dataSource.length === 0) {
            this.dataSource = result.data.farmTravelCalendarStoreList.map(e => {
              return {
                ...e,
                subTile: e.subTile ? e.subTile : ''
              }
            })
          }
          this.numberBusinesses = result.data.shopCount
        } else {
          Message.error(result.msg)
        }
      })
    },

    handleOkClear() {
      if (this.delShopIds_copy.length > 0) {
        this.delShopIds_copy.forEach(e => {
          this.delShopIdsCopy.push(e.id)
        })
      }
      console.log(this.delShopIdsCopy)

      let a = []
      let b = this.listData

      b.forEach(e => {
        if (!this.delShopIdsCopy.includes(e.id)) {
          a.push(e)
        }
      })

      this.listData = a
      this.selectedRowKeys = []
      //   this.handleCancelClear()
    },
    handleCancelClear() {
      this.visibleClear = false
    },
    handleClearCopy() {
      let _this = this
      let arrId = this.dataSource.map(e => e.storeId).toString()

      console.log(this.delShopIdsCopy, '1')

      getAction(
        `/api/commodity/farmMainBody/getMainBodyByLabelList?delShopIds=${_this.delShopIdsCopy.toString()}&allLabel=${
          _this.searchIndex
        }&scenicLabel=${_this.typeObjData.scenicLabel}&pickLabel=${_this.typeObjData.farmExperiencePick}&anglingLabel=${
          _this.typeObjData.experienceFish
        }&shopIds=${arrId}`
      ).then(result => {
        if (result.code == 200) {
          _this.listData = result.data
          _this.visibleClear = true
        } else {
          Message.error(result.msg)
        }
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      this.selectedRowKeys = selectedRowKeys
      this.delShopIds_copy = selectedRows
    },
    handleVClear() {
      return (
        <a-modal
          v-model={this.visibleClear}
          title="删除商家"
          onOk={this.handleOkClear}
          onCancel={this.handleCancelClear}
        >
          <div class="box_one">
            <span>店铺名称：</span>
            <a-input allow-clear style={{ width: '85%' }} v-model={this.name} placeholder="请输入店铺名称" />
          </div>
          <div style={{ marginTop: '25px' }}>
            <a-table
              rowSelection={{
                selectedRowKeys: this.selectedRowKeys,
                onChange: this.onSelectChange
              }}
              scroll={{ y: 240 }}
              columns={this.getColumnsStop()}
              data-source={this.name ? this.listDataCopy : this.listData}
              pagination={false}
            ></a-table>
          </div>
        </a-modal>
      )
    },
    renderIcons() {
      // <a-button onClick={this.searchMerchants}>搜索商家</a-button>
      return (
        <div class="box_box">
          <a-radio-group class="radio_box" v-model={this.searchIndex}>
            <a-radio class="radio_child" value={'1'}>
              含有所有标签
            </a-radio>
            <a-radio class="radio_child" value={'0'}>
              含有其中一个标签
            </a-radio>
          </a-radio-group>
          <a-tag
            color="blue"
            style={{
              height: '32px',
              //   marginLeft: '10px',
              fontSize: '14px',
              lineHeight: '32px',
              borderRadius: '5px'
            }}
          >
            <a-icon type="info-circle" style={{ marginRight: '10px' }} />
            按照标签搜索到 {this.numberBusinesses} 个有效实体
          </a-tag>
          <a-button class="clesr_shop" onClick={() => this.handleClearCopy()}>
            删除商家
          </a-button>
        </div>
      )
    },

    renderTable() {
      return <a-table columns={this.getColumns()} data-source={this.dataSource} pagination={false}></a-table>
    },

    renderModal() {
      return (
        <a-modal v-model={this.visible} title="更换商家" onOk={this.handleOk} onCancel={this.handleCancel}>
          <div class="box_one">
            <span>店铺名称：</span>
            <a-input allow-clear style={{ width: '85%' }} v-model={this.name} placeholder="请输入店铺名称" />
          </div>
          <div style={{ marginTop: '25px' }}>
            <a-table
              scroll={{ y: 240 }}
              columns={this.getColumnsStop()}
              data-source={this.name ? this.listDataCopy : this.listData}
              pagination={false}
              customRow={(record, index) => {
                return {
                  on: {
                    // 事件
                    click: event => {
                      this.name = record.mainBodyName
                      this.searchData = record
                    } // 点击行
                  }
                }
              }}
            ></a-table>
          </div>
        </a-modal>
      )
    },

    handleCancel() {
      this.visible = false
    },
    handleOk() {
      this.visible = false
      this.name = null

      this.dataSource.forEach(e => {
        if (e.storeId == this.chooseData.storeId) {
          e.storeName = this.searchData.mainBodyName
          e.storeId = this.searchData.id
        }
      })
      this.$forceUpdate()
    },
    handleSrarchStore() {
      this.listDataCopy = this.listData.filter(e => e.mainBodyName.indexOf(this.name) !== -1)
    }
  },

  render() {
    const scopedSlots = {
      title: () => {
        return (
          <span class="sub-old">
            {this.title}
            <span class="title_copy"> {this.titleCopy}</span>
          </span>
        )
      }
    }
    return (
      <Card {...{ scopedSlots }}>
        {this.renderIcons()}
        {this.renderTable()}
        {this.renderModal()}
        {this.handleVClear()}
      </Card>
    )
  }
}
</script>
<style lang="less" scoped>
.box_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.clesr_shop {
  border-color: #ed4014;
  color: #ed4014 !important;
  align-self: center;
  box-sizing: border-box;
  border-radius: 5px;
}

.card_box {
  .ant-card-body {
    padding: 12px;
  }
}
.box_one {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.title_copy {
  color: #999;
  font-size: 14px;
}
.radio_box {
  .radio_child {
    border: 1px solid #eee;
    padding: 5px 5px 5px 10px;
    border-radius: 5px;
  }
}
</style>
